<template>
  <PcUserPage contentComponent="AppPcUserMap" />
</template>

<script>
import PcUserPage from '@/templates/user/AppPcUserPage'

export default {
  name: 'ThePcUserMap',
  components: {
    PcUserPage,
  },
  data() {
    return {}
  },
  created() {
    // PC表示時のマップコントロール設定
    this.$store.dispatch('map/setZoomControl', true)
  },
  computed: {},
  methods: {},
}
</script>
